<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Cập nhật đề xuất chương trình xúc tiến đầu tư quốc gia
          </CCardHeader>
          <CCardBody>
            <CForm>
              <div class="w-100">
                <div class="pdf-wrapper">
                  <div class="modal-body modal-container">
                    <div class="pdf-page">
                      <div class="fs-16">
                        <div id="pdf1" class="pdf-content ml-3 mr-3">
                          <div class="text-center fs-16">
                            <strong class="bold">Mẫu C.I.1</strong><br>
                            <strong>Văn bản đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia hàng
                              năm</strong><br>
                            <p class="font-italic">(Điểm b, Khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP)</p>
                            <hr>
                          </div>
                          <div class="row fs-16">
                            <div class="col-6">
                              <ValidationProvider name="Tên cơ quan chủ trì" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="coQuanChuTri" type="text" class="form-control"
                                           placeholder="Tên Bộ/UBND Tỉnh"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.coQuanChuTri"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-6"></div>
                            <div class="col-md-6">
                              <!--                        <CInput placeholder="Tên Bộ/UBND Tỉnh" horizontal :value.sync="item.coQuanChuTri"/>-->
                              <ValidationProvider name="Số văn bản" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input id="soVanBan" type="text" class="form-control"
                                           placeholder="Số:"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.soVanBan"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Số:" horizontal :value.sync="item.soVanBan"/>-->
                              <p class="font-weight">V/v dự kiến chương trình đầu tư năm
                                {{ detailItem.nam_ChuongTrinh = Number(getYear(detailItem.ngayVanBan)) + 1 }}</p>
                            </div>
                            <div class="col-md-6">
                              <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt
                                Nam</p>
                              <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                              <p class="font-weight font-italic" style="float: right; display: flex">
                                <ValidationProvider name="Địa điểm" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                              <div role="group" class="form-group form-row">
                                <div class="col-sm-9">
                                  <input id="diaDiem" type="text" class="form-control"
                                         placeholder="Địa điểm"
                                         :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                         v-model="detailItem.diaDiem"/>
                                  <div class="invalid-feedback" v-if="!valid">
                                    <ul>
                                      <li v-for="e in errors" :key="e">{{ e }}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              </ValidationProvider>
                              <!--                          <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>-->
                              <span class="mt-2">, ngày {{
                                  getDay(detailItem.ngayVanBan)
                                }} tháng {{
                                  getMonth(detailItem.ngayVanBan)
                                }} năm {{ getYear(detailItem.ngayVanBan) }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="text-center">
                            <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
                          </div>
                          <div>
                            <div class="col-12">
                              <p style="text-indent: 2em">Căn cứ Điều ... Luật Đầu tư, Điều ... Nghị định số
                                31/2021/NĐ-CP ngày
                                26/3/2021, Thông tư số .. /2021/TT-BKHĐT ngày ... quy định biểu mẫu thực hiện hoạt động
                                đầu tư tại Việt Nam, đầu tư tại Việt Nam, đầu tư từ Việt Nam ra nước ngoài và xúc tiến
                                đầu tư và hướng dẫn của Bộ Kế hoạch và Đầu tư tại công văn số .. ngày ...,
                                {{ detailItem.coQuanChuTri }} xin báo cáo và đề xuất một số nội dung như sau:</p>
                              <p style="text-indent: 2em" class="font-italic">(Trong văn bản phải đề cập các nội dung:
                                đánh giá sơ bộ kết quả hoạt động xúc tiến đầu tư năm trước; điều kiện, xu thế và bối
                                cảnh gắn với tiềm năng của vùng, lãnh thổ; sự cần thiết, tác động, ý nghĩa của các hoạt
                                động đối với liên kết vùng. Từ đó, nêu những lĩnh vực, đối tác cần hướng tới...)</p>
                              <ValidationProvider name="Đánh giá hoạt động năm trước" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-12">
                              <textarea id="danhGiaHoatDongNamTruoc" type="text" class="form-control" :rows="1"
                                        placeholder="Nhập đánh giá"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.danhGiaHoatDongNamTruoc"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-12">
                              <div style="display: flex">
                                <p style="text-indent: 2em;">Do đó, cần phải thực hiện các hoạt động đề xuất</p>
                                <ValidationProvider name="Nội dung đề xuất" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                                  <div role="group" class="form-group form-row">
                                    <div class="col-12 ml-3">
                                      <textarea id="noiDung" type="text" class="form-control" :rows="1"
                                                placeholder="Nhập nội dung"
                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                                v-model="detailItem.noiDung"/>
                                      <div class="invalid-feedback" v-if="!valid">
                                        <ul>
                                          <li v-for="e in errors" :key="e">{{ e }}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </ValidationProvider>
                              </div>
                              <div style="display: flex">
                                <p>hướng tới ... <span class="font-italic">(đạt mục tiêu, kết hợp ngân sách kèm xã hội hóa...)</span>
                                </p>
                                <ValidationProvider name="Mục tiêu" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                                  <div role="group" class="form-group form-row">
                                    <div class="col-12 ml-3">
                                      <textarea id="mucTieu" type="text" class="form-control" :rows="1"
                                                placeholder="Nhập mục tiêu"
                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                                v-model="detailItem.mucTieu"/>
                                      <div class="invalid-feedback" v-if="!valid">
                                        <ul>
                                          <li v-for="e in errors" :key="e">{{ e }}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </ValidationProvider>
                              </div>
                              <div style="display: flex">
                                <p style="text-indent: 2em">{{ detailItem.coQuanChuTri }} đề nghị Bộ Kế hoạch và Đầu tư
                                  đưa {{ itemEmit.soLuong | formatNumber }}
                                  <span class="font-italic"> (số lượng)</span> hoạt động xúc tiến đầu tư vào</p>
                              </div>
                              <div style="display: flex">
                                <p>chương trình xúc tiến đầu tư quốc gia năm {{ detailItem.nam_ChuongTrinh }} với tổng
                                  kinh phí đề xuất: {{ itemEmit.tongKinhPhi | formatNumber }} triệu đồng, trong đó:</p>
                              </div>
                              <div style="display: flex">
                                <p>{{ itemEmit.kinhPhi_ChuongTrinhXTDTQG | formatNumber }} triệu đồng, từ nguồn kinh phí
                                  của Chương trình xúc tiến đầu tư quốc gia.</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p style="text-indent: 2em">{{ detailItem.coQuanChuTri }} xin gửi kèm theo các tài liệu:</p>
                            <div class="col-12 row">
                              <div class="col">
                                <p>1. Biểu tổng hợp đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia;</p>
                                <p>2. Đề án của từng hoạt động xúc tiến đầu tư;</p>
                                <p>3. Bảng dự toán kinh phí của từng hoạt động xúc tiến đầu tư đề xuất.</p>
                              </div>
                              <div class="col">
                                <CButton class="mt-3" color="primary"
                                         @click="toggleCreateOrUpdatePhuLucModal({show:true})">
                                  <i name="cil-highlighter"/> Mẫu C.I.2
                                </CButton>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="col-12 row">
                              <div class="col">
                                <div class="float-left">
                                  <span class="font-italic font-weight-bold fs-18">Nơi nhận:</span> <br>
                                  <span class="font-weight">- Như trên;</span> <br>
                                  <span class="font-weight">- Lưu: VT, ...</span> <br>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col"></div>
                              <div class="col text-center">
                                <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan chủ trì</span> <br>
                                <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                                <br>
                                <br>
                                <br>
                              </div>
                            </div>
<!--                            <div class="col-12">-->
<!--                              <ValidationProvider name="Nội dung điều chỉnh" rules="required"-->
<!--                                                  v-slot="{ errors, valid , touched }">-->
<!--                                <div role="group" class="form-group form-row">-->
<!--                                  <div class="col-12 ml-3">-->
<!--                                      <textarea id="noiDungDieuChinh" type="text" class="form-control" :rows="1"-->
<!--                                                placeholder="Nhập nội dung điều chỉnh"-->
<!--                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                                v-model="dieuChinh.noiDungDieuChinh"/>-->
<!--                                    <div class="invalid-feedback" v-if="!valid">-->
<!--                                      <ul>-->
<!--                                        <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </ValidationProvider>-->
<!--                              <ValidationProvider name="Lý do, căn cứ điều chỉnh" rules="required"-->
<!--                                                  v-slot="{ errors, valid , touched }">-->
<!--                                <div role="group" class="form-group form-row">-->
<!--                                  <div class="col-12 ml-3">-->
<!--                                      <textarea id="lyDo_CanCuDieuChinh" type="text" class="form-control" :rows="1"-->
<!--                                                placeholder="Nhập lý do, căn cứ điều chỉnh"-->
<!--                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                                v-model="dieuChinh.lyDo_CanCuDieuChinh"/>-->
<!--                                    <div class="invalid-feedback" v-if="!valid">-->
<!--                                      <ul>-->
<!--                                        <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </ValidationProvider>-->
<!--                              <ValidationProvider name="Tính phù hợp của việc điều chỉnh" rules="required"-->
<!--                                                  v-slot="{ errors, valid , touched }">-->
<!--                                <div role="group" class="form-group form-row">-->
<!--                                  <div class="col-12 ml-3">-->
<!--                                      <textarea id="tinhPhuHopCuaViecDieuChinh" type="text" class="form-control" :rows="1"-->
<!--                                                placeholder="Nhập tính phù hợp của việc điều chỉnh"-->
<!--                                                :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                                v-model="dieuChinh.tinhPhuHopCuaViecDieuChinh"/>-->
<!--                                    <div class="invalid-feedback" v-if="!valid">-->
<!--                                      <ul>-->
<!--                                        <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </ValidationProvider>-->
<!--                            </div>-->
                          </div>
                          <div class="form-group row mb-0 mt-3">
                            <div class="col-12">
                              <b class="mt-5">Đính kèm file</b>
                              <FileUpload ref="upload" @onChange="changeFiles" :type="type" :attachments="Attachments"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CForm>
            <CElementCover v-if="isLoading"/>
            <DeXuatCTXTDTQGPhuLucUpdateModal :item="detailItem" @onSubmit="emitDataPhuLuc($event)"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="primary" class="mr-2" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu
              lại
            </CButton>
            <CButton color="danger" class="mr-2" @click="handleSubmit(saveItem2)" :disabled="invalid || isSaving">Nộp
              văn bản
            </CButton>
            <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
            <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  GET_DEXUATCTXTDTQUOCGIA, TOGGLE_UPDATE_PHULUC_MODAL, UPDATE_DEXUATCTXTDTQUOCGIA
} from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import DeXuatCTXTDTQGPhuLucUpdateModal from '@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGPhuLucUpdateModal'
import FileUpload from '@/components/file-upload/FileUpload'
import appConfig from '@/shared/appConfig'

export default {
  name: 'DeXuatCTXTDTUpdate',
  components: {
    DeXuatCTXTDTQGPhuLucUpdateModal,
    FileUpload
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDTQuocGia',
      isLoading: false,
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: this.detailItem,
      itemEmit: {
        soLuong: 0,
        tongKinhPhi: 0,
        kinhPhi_ChuongTrinhXTDTQG: 0,
        chiTietDeXuatCTXTDTQuocGia: []
      },
      dieuChinh: {
        noiDungDieuChinh: '',
        lyDo_CanCuDieuChinh: '',
        tinhPhuHopCuaViecDieuChinh: ''
      }
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTQG', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTQG', {
      getItem: GET_DEXUATCTXTDTQUOCGIA,
      updateItem: UPDATE_DEXUATCTXTDTQUOCGIA,
      toggleCreateOrUpdatePhuLucModal: TOGGLE_UPDATE_PHULUC_MODAL
    }),
    print () {
      const data = {
        item: this.detailItem,
        itemEmit: this.itemEmit
      }
      localStorage.setItem('de_xuat_ct_xtdt_quoc_gia', JSON.stringify(data))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt-qg/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt-qg' })
    },
    async saveItem () {
      const validate = await this.$refs.form.validate()
      if (validate) {
        this.detailItem.trangThai = 1
        this.detailItem.dinhKem = JSON.stringify(this.Attachments)
        // if (this.item.id) {
        //   await this.updateItem(this.item)
        // } else {
        const data = {
          id: this.detailItem.id,
          guid: this.detailItem.guid,
          deXuatCTXTDTQuocGiaId: this.detailItem.id,
          soVanBan: this.detailItem.soVanBan,
          nam_ChuongTrinh: this.detailItem.nam_ChuongTrinh,
          ngayVanBan: this.detailItem.ngayVanBan,
          coQuanChuTri: this.detailItem.coQuanChuTri,
          diaDiem: this.detailItem.diaDiem,
          danhGiaHoatDongNamTruoc: this.detailItem.danhGiaHoatDongNamTruoc,
          noiDung: this.detailItem.noiDung,
          mucTieu: this.detailItem.mucTieu,
          // chiTietDeXuatCTXTDTQuocGia: this.itemEmit.chiTietDeXuatCTXTDTQuocGia,
          trangThai: this.detailItem.trangThai
        }
        // console.log(data)
        await this.updateItem(data)
        // }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          // this.$emit('refresh-list', true)
          await this.$router.push({ path: '/de-xuat-ct-xtdt-qg' })
        }
      } else {
        this.$toast.error('Thông tin đang thiếu hoặc sai định dạng. Vui lòng kiểm tra lại')
      }
    },
    async saveItem2 () {
      const validate = await this.$refs.form.validate()
      if (validate) {
        this.detailItem.trangThai = 2
        this.detailItem.dinhKem = JSON.stringify(this.Attachments)
        // if (this.item.id) {
        //   await this.updateItem(this.item)
        // } else {
        const data = {
          id: this.detailItem.id,
          guid: this.detailItem.guid,
          deXuatCTXTDTQuocGiaId: this.detailItem.id,
          soVanBan: this.detailItem.soVanBan,
          nam_ChuongTrinh: this.detailItem.nam_ChuongTrinh,
          ngayVanBan: this.detailItem.ngayVanBan,
          coQuanChuTri: this.detailItem.coQuanChuTri,
          diaDiem: this.detailItem.diaDiem,
          danhGiaHoatDongNamTruoc: this.detailItem.danhGiaHoatDongNamTruoc,
          noiDung: this.detailItem.noiDung,
          mucTieu: this.detailItem.mucTieu,
          // chiTietDeXuatCTXTDTQuocGia: this.itemEmit.chiTietDeXuatCTXTDTQuocGia,
          trangThai: this.detailItem.trangThai
        }
        await this.updateItem(data)
        // }
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          // this.toggleModal({ show: false })
          // this.$emit('refresh-list', true)
          await this.$router.push({ path: '/de-xuat-ct-xtdt-qg' })
        }
      } else {
        this.$toast.error('Thông tin đang thiếu hoặc sai định dạng. Vui lòng kiểm tra lại')
      }
    },
    emitDataPhuLuc (data) {
      this.itemEmit = data
      console.log('Phụ lục emit: ', data)
      let countSoLuong = 0
      let countTongKinhPhi = 0
      let countKinhPhiCTXTDTQG = 0
      for (const i in data.chiTietDeXuatCTXTDTQuocGia) {
        countKinhPhiCTXTDTQG += data.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG
        countTongKinhPhi += data.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG + data.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong + data.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac
        countSoLuong++
        // data.chiTietDeXuatCTXTDTQuocGia[i].loaiHoatDong = Number(data.chiTietDeXuatCTXTDTQuocGia[i].loaiHoatDong)
      }
      this.itemEmit.soLuong = countSoLuong
      this.itemEmit.tongKinhPhi = countTongKinhPhi
      this.itemEmit.kinhPhi_ChuongTrinhXTDTQG = countKinhPhiCTXTDTQG
      this.itemEmit.chiTietDeXuatCTXTDTQuocGia = data.chiTietDeXuatCTXTDTQuocGia
    },
    countData () {
      let countSoLuong = 0
      let countTongKinhPhi = 0
      let countKinhPhiCTXTDTQG = 0
      for (const i in this.detailItem.chiTietDeXuatCTXTDTQuocGia) {
        countKinhPhiCTXTDTQG += this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG
        countTongKinhPhi += this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG + this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong + this.detailItem.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac
        countSoLuong++
      }
      this.itemEmit.soLuong = countSoLuong
      this.itemEmit.tongKinhPhi = countTongKinhPhi
      this.itemEmit.kinhPhi_ChuongTrinhXTDTQG = countKinhPhiCTXTDTQG
    },
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    await this.getItem(this.$route.params.id)
    if (this.detailItem.dinhKem) this.Attachments = JSON.parse(this.detailItem.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.countData()
    this.itemEmit.chiTietDeXuatCTXTDTQuocGia = this.detailItem.chiTietDeXuatCTXTDTQuocGia
  }
}
</script>

<style scoped>

</style>
