import { render, staticRenderFns } from "./DeXuatCTXTDTQGUpdate.vue?vue&type=template&id=588c1976&scoped=true&"
import script from "./DeXuatCTXTDTQGUpdate.vue?vue&type=script&lang=js&"
export * from "./DeXuatCTXTDTQGUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588c1976",
  null
  
)

export default component.exports